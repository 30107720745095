@import 'variables';

.flex {
  display: flex;
}

.f-flex {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
}
.f-flex-row {
  flex-direction: row !important;
}

.w100 {
  width: 100%;
}

.napa-cursor-pointer {
  cursor: pointer;
}

.napa-vertical-divider {
  width: 1px;
  height: 100%;
  background: $field-outline-color;
}

.napa-table-vertical-divider {
  @extend .napa-vertical-divider;

  height: 48px;
  margin-right: -8px;
}

.napa-sm-vertical-divider {
  @extend .napa-vertical-divider;

  height: 24px;
  background: $light-grey-color;
}

.napa-modality-options-list {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: min-content;
  gap: 8px;
}

.napa-modality-option {
  font-size: $font-size-md;
  font-family: $font-family-medium;
  padding: 6px 8px 4px 8px;
  border-radius: 6px;
  width: min-content;

  &--green {
    color: $green-color;
    background: $light-green-color;
  }

  &--gray {
    color: $color-text-gray;
    background: $field-outline-color;
  }
}

$dark-gray-color: #1b1c1c;
$dark-pink-color: #a3238f;
$yellow-color: #f7bd3a;
$orange-color: #f47421;
$red-color: #e74c3c;
$green-color: #8dc63f;
$blue-color: #1a7db8;

$light-grey-color: #cbd2d7;
$light-green-color: #ecfdd3;
$light-pink-color: #ffeffd;
$light-blue-color: #edf9fe;
$light-yellow-color: #fff8e8;
$light-orange-color: #fff1e8;
$light-red-color: #ffe8e8;

.napa-email-delivery-status {
  padding: 5px 10px;
  border-radius: 6px;

  &__green {
    color: $green-color;
    background-color: $light-green-color;
  }

  &__pink {
    color: $dark-pink-color;
    background-color: $light-pink-color;
  }

  &__red {
    color: $red-color;
    background-color: $light-red-color;
  }

  &__orange {
    color: $orange-color;
    background-color: $light-orange-color;
  }
}

.card {
  padding: 16px;
  background: $color-white;
  border-radius: $card-border-radius;
}

span[inlinesvg] {
  display: block;

  svg {
    display: block;
  }
}

.disabled {
  pointer-events: none;
}

.i-black {
  svg {
    path {
      stroke: $dark-gray-color;
    }
    circle {
      fill: $dark-gray-color;
    }
    line {
      stroke: $button-border-primary-color;
    }
  }
}

.i-primary {
  svg {
    path {
      stroke: $button-border-primary-color;
    }
    circle {
      fill: $button-border-primary-color;
    }
    line {
      stroke: $button-border-primary-color;
    }
  }
}

.i-white {
  svg {
    path {
      stroke: $color-white;
    }
    circle {
      fill: $color-white;
    }
    line {
      stroke: $color-white;
    }
  }
}

.color-primary {
  color: $primary-color;
}

.text-underline {
  text-decoration: underline;
}

.napa-borderless-button {
  padding: 0;
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
  font-family: $font-family-regular;
}

.napa-icon-button {
  @extend .napa-borderless-button;
}
