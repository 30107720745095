@import '../mixins';

$first-column-width: 380px;
$second-column-width: auto;
$third-column-width: 130px;
$fourth-column-width: 80px;
$fifth-column-width: 40px;

.napa-checklist-table {
  .napa-checklist-table-head-data,
  .napa-checklist-item-data {
    box-sizing: border-box;
  }

  .napa-checklist-table-head-data {
    padding: 0 12px !important;
  }

  .napa-checklist-item-data {
    height: auto;
    padding: 8px 12px !important;
  }

  .napa-checklist-table-head-data,
  .napa-checklist-item-data {
    &:first-of-type {
      @include table-column-width($first-column-width, $first-column-width, $first-column-width);
    }
  }

  .napa-checklist-table-head-data,
  .napa-checklist-item-data {
    &:nth-of-type(2) {
      @include table-column-width($second-column-width);
    }
  }

  .napa-checklist-table-head-data,
  .napa-checklist-item-data {
    &:nth-of-type(3) {
      @include table-column-width($third-column-width, $third-column-width, $third-column-width);
    }
  }

  .napa-checklist-table-head-data,
  .napa-checklist-item-data {
    &:nth-of-type(4) {
      @include table-column-width(auto, 130px, 130px);
    }
  }

  .napa-checklist-table-head-data,
  .napa-checklist-item-data {
    &:nth-of-type(5) {
      @include table-column-width(auto, auto, max-content);
    }
  }
}

.napa-checklist-table-checkbox-placeholder {
  width: 18px;
  margin-right: 8px;
}
