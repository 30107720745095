@import './variables';

// Menu
.mat-mdc-menu-panel.napa-regular-menu {
  min-width: 200px;
  border-radius: 12px;
  background: #ffffff;
  box-shadow: 0 4px 32px 0 rgba(0, 0, 0, 0.16) !important;

  .mat-mdc-menu-content {
    padding: 0;

    .mat-mdc-menu-item {
      height: 40px;
      min-height: 40px;
      padding: 0 12px;

      .mat-mdc-menu-item-text {
        display: grid;
        grid-auto-flow: column;
        grid-auto-columns: max-content;
        color: $primary-color;
        align-items: center;
        gap: 8px;
      }
    }
  }
}

// Dialog
.mat-mdc-dialog-container {
  .mdc-dialog__container .mat-mdc-dialog-surface {
    border-radius: 16px;
    box-shadow: 0 4px 64px 0 rgba(0, 0, 0, 0.24);
    background: $body-color;
  }
}

// Radio
.mat-mdc-radio-group.napa-radio-group-layout-column {
  display: grid;
  grid-auto-flow: column;
  gap: 16px;
}

.mat-mdc-radio-button {
  &:not(.mat-mdc-radio-checked) {
    .mdc-radio__outer-circle {
      border-color: $radio-button-border-color !important;
      background: $color-white;
    }
  }

  .mdc-radio__native-control {
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
  }
}

// Tooltip
.mat-mdc-tooltip-panel .mat-mdc-tooltip .mdc-tooltip__surface {
  padding: 10px 16px 9px 16px;
  letter-spacing: normal;
  font-size: $font-size-md;
  background: $dark-gray-color;
  box-shadow: 0 12px 32px 0 rgba(0, 0, 0, 0.15);
  font-family: $font-family-regular;
  border-radius: 12px;
  line-height: 18px;
  max-width: 280px;
}

// Slide Toggle
.mdc-switch {
  width: 30px !important;

  .mdc-switch__ripple,
  .mdc-switch__shadow {
    display: none !important;
  }

  .mdc-switch__track {
    height: 16px !important;

    &::before,
    &::after {
      border-radius: 8px !important;
    }

    &::before {
      background: $field-outline-color !important;
    }

    &::after {
      background: $primary-color !important;
    }
  }

  .mdc-switch__handle-track {
    left: 2px !important;
    width: 12px !important;

    .mdc-switch__handle {
      width: 12px !important;
      height: 12px !important;

      &::before {
        background-color: $primary-color !important;
      }
    }
  }

  &--selected {
    .mdc-switch__handle-track {
      transform: translateX(14px) !important;
    }

    .mdc-switch__handle::after {
      background-color: $accent-color !important;
    }
  }

  &--unselected {
    .mdc-switch__handle::after {
      background-color: $primary-color !important;
    }
  }
}

.session-notes-overlay {
  min-height: 200px;
}

.custom-add-user {
  max-height: 90vh !important;
}

.search-option {
  padding: 5px;
  margin: 10px 10px;
}

.search-mat-select {
  .mat-mdc-text-field-wrapper {
    padding: 3px 20px;
    border-radius: 8px;
    background-color: rgb(236, 239, 241) !important;
  }

  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }

  .mat-mdc-form-field-infix {
    padding: 0 !important;
    max-height: 20px;
    min-height: 20px;
  }

  .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
    border: none !important;
  }
}

.mat-calendar-content {
  padding: 0 !important;
}

.mat-calendar-table-header-divider::after {
  background: none !important;
}
.mat-calendar-body-label {
  display: none;
}
.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: $blue-color !important;
  font-weight: 600 !important;
  border-color: rgb(245 247 249) !important;
}

.mat-calendar-body-selected {
  background-color: rgb(219, 235, 243) !important;
  color: $blue-color !important;
  border-radius: 8px !important;
}

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgb(219, 235, 243) !important;
  color: $blue-color !important;
  border-radius: 8px !important;
}

.mat-calendar-body-selected.mat-calendar-body-today {
  box-shadow: none !important;
}

.mat-calendar-table-header th {
  font-size: 16px !important;
}

.mat-calendar-body-cell {
  font-size: 16px !important;
}

.mat-calendar-body-cell-content {
  padding-top: 5px;
  width: 32px;
  height: 32px;
}

.mdc-menu-surface--open {
  border-radius: 12px !important;
}

// Expansion Panel
.napa-email-templates-folder {
  box-shadow: none !important;

  .mat-expansion-panel-header {
    border-radius: 8px;
    padding: 0 12px 0 8px;
    cursor: auto !important;

    .mat-content {
      margin-right: 0;
    }
  }

  &.mat-expansion-panel:not(.mat-expanded) {
    .mat-expansion-panel-header:hover {
      background: $body-color;
    }
  }

  &.active.mat-expansion-panel {
    .mat-expansion-panel-header {
      background: $light-blue-color !important;
    }
  }

  .mat-expansion-panel-body {
    padding: 0 0 0 22px;
  }
}

// Overlay

.holding-area-panel {
  pointer-events: none;
}
